// JavaScript Document
"use strict";
var Linkham = {
    /*
     Function Name : init
     script to functions call on page load
     @version: 30-1-2020 updated
     */
    init: function () {

        // Linkham.responsiveNav();
        Linkham.AccordionToggleClass();
        Linkham.wizardSteps();
        $('.tooltip-icon').tooltip()
        $('.date-picker').datepicker({});
        Linkham.loadResize();
        Linkham.Scrollto();
        Linkham.LandingpageSlider();

        jQuery(window).resize(Linkham.loadResize);

    },

    loadResize: function () {
        //load resize script starts 

        //script to get windows height and width
        //var window_h = jQuery(window).height();
        var window_w = document.body.clientWidth;
        //condition for the device width greater then and equal to 768
       
        if (window_w > 991) {
            Linkham.ScrollFix();
        }


        //condition for the device width less then and equal to 768
        else {
                $('.sticky').removeClass("fix");
                $('.cta-spacer').removeClass("active");
        }



        //load resize script ends 
    },


    responsiveNav: function () {
        responsivemenu.init({
            wrapper: document.querySelector('.navigation_container1'),
            togglecontent: 'menu',
            sticky: 0,
            animateduration: 1000,
            subanimateduration: 1000,
        });
    },

    AccordionToggleClass: function () {
        $('.accordion-content .card .card-header').click(function () {
            var item = $(this);
            var card = $('.accordion-content .card');
            $(card).siblings().children('.card-header').removeClass('current');
            //item.addClass("current");

            if ($(this).hasClass('current')) {
                $(this).removeClass('current');
            }

            else {
                item.addClass("current");
            }
        });
    },

    wizardSteps: function () {
        var navListItems = $('div.setup-panel div a'),
            allWells = $('.setup-content'),
            allNextBtn = $('.nextBtn');

        allWells.hide();

        navListItems.click(function (e) {
            e.preventDefault();
            var $target = $($(this).attr('href')),
                $item = $(this);

            if (!$item.hasClass('disabled')) {
                navListItems.removeClass('btn-primary').addClass('btn-default');
                $item.addClass('btn-primary');
                allWells.hide();
                $target.show();
               // $target.find('input:eq(0)').focus();
            }
        });

        allNextBtn.click(function () {
            var curStep = $(this).closest(".setup-content"),
                curStepBtn = curStep.attr("id"),
                nextStepWizard = $('div.setup-panel div a[href="#' + curStepBtn + '"]').parent().next().children("a"),
                curInputs = curStep.find("input[type='text'],input[type='url']"),
                isValid = true;
                         

            if (isValid)
                nextStepWizard.removeAttr('disabled').trigger('click');
        });

        $('div.setup-panel div a.btn-primary').trigger('click');
    },

    ScrollFix: function () {
        if ($(".sticky").length) {

            var fixmeTop = $('.sticky').offset().top;
            $(window).scroll(function () {
                var currentScroll = $(window).scrollTop();
                if (currentScroll >= fixmeTop) {
                    $('.sticky').addClass("fix");
                    $('.cta-spacer').addClass("active");
                } else {
                    $('.sticky').removeClass("fix");
                    $('.cta-spacer').removeClass("active");
                }
            });

        }

    },

    Scrollto: function () {
        if ($("#travelFindOut").length) {
            $("#travelFindOut").click(function () {
                $('html,body').animate({
                    scrollTop: $(".products-cta").offset().top}, 'slow');
            });
        }

        //$("#travelFindOut").click(function () {
        //    $('html,body').animate({
        //        scrollTop: $(".products-cta").offset().top},'slow');
        //});

        if ($("#InnertravelFindOut").length) {
            $("#InnertravelFindOut").click(function () {
                $('html,body').animate({
                    scrollTop: $(".products-cta").offset().top - 0
                }, 'slow');
            });
        }
       
        if ($("#covid_click").length) {
            $("#covid_click").click(function () {
                $('html,body').animate({
                    scrollTop: $("#covid19_content").offset().top - 150
                }, 'slow');
            });
        }
        
 
    },

    LandingpageSlider: function () {
        $('.Landing-page-Slider').owlCarousel({
            loop: true,
            margin: 10,
            responsiveClass: true,
            responsive: {
                0: {
                    items: 1,
                    nav: true
                },
                600: {
                    items: 3,
                    nav: false
                },
                1000: {
                    items: 5,
                    nav: true,
                    loop: false,
                    margin: 20
                }
            }
        })

    }




};
// when the page is ready, initialize everything
$(document).ready(function () {
    Linkham.init();
});


